﻿
@media print {

    a[href]:after {
        content: none;
    }

    abbr[title]:after {
        content: none;
    }

    body {
        font-size: 10px;
        line-height: 1.224488571;
    }

    .content-wrapper {
        border: none;

        > .content-heading {
            border: none;
            font-size: 20px;
            margin: 10px 0;
            padding: 0;
        }
    }

    .table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
        padding: 2px 4px;
    }

    .profiler-results {
        display: none;
    }
}
