﻿@import "variables";
@import "mixins";

.gridster {
    /*width: 980px;*/
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: hidden;

    > ul {
        margin: 0;

        > li {
            list-style: none !important;
            overflow: hidden;
            min-width: auto;

            &.gs-w {
                /*background: #FFF;*/
                cursor: default;
                /*-webkit-box-shadow: 0 0 5px #e6e6e6;
                box-shadow: 0 0 5px #e6e6e6;
                border: 1px solid #e6e6e6;*/
                &[class^="col-"], &[class*=" col-"] {
                    margin: 0 !important;
                    padding: 0 !important;

                    > div.panel {
                        height: 100% !important;
                    }
                }
            }

            &.gs-w.no-header {
                cursor: move;
            }

            &.gs-w.no-resize {
                .gs-resize-handle {
                    display: none;
                }
            }

            &.gs-w header, &.gs-w .panel-heading {
                /*display: block;*/
                /*border-bottom: 1px solid rgba(0,0,0,0.1);*/
                /*padding: 7px 14px;*/
                /*color: #888;*/
                /*font-family: Verdana, Arial, Serif;
                font-size: 11px;
                line-height: 1.14286em;
                font-weight: normal;*/
                /*text-transform: uppercase;*/
                font-size: 12px;
                padding: 10px;
            }

            &.gs-w > div {
                /*margin: 14px;*/
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                > div.title, > div.message {
                    text-align: center;
                    color: #bbb;
                    line-height: 2em;
                }

                > div.title {
                    text-transform: uppercase;
                    font-size: 1.3em;
                }

                > div.message {
                    font-style: italic;
                }
            }

            &.gs-w > div.chart {
                margin: 0 14px;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }
        }
    }

    .player {
        -webkit-box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
        box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
    }

    .preview-holder {
        border: none !important;
        border-radius: 0 !important;
        background: rgba(0,0,0,0.3);
    }

    .panel-heading .editMode {
        display: none;
        cursor: pointer;
    }
}

#tmp {
    display: none;
}

.grid-darkcolorBg {
    background: rgba(0,0,0,0.5);
}

#gridsterTileStore {
    display: none;
    position: fixed;
    right: 5px;
    max-width: 20%;
    z-index: 99;
    top: 30%;

    > .panel-heading {
        span {
            float: right;
            display: inline;
            cursor: pointer;
        }
    }

    #gridsterTileList {
        > div {
            background: #FFF;
            cursor: copy;
            -webkit-box-shadow: 0 0 5px #e6e6e6;
            box-shadow: 0 0 5px #e6e6e6;
            border: 1px solid #e6e6e6;
            margin: 5px auto;
            display: block;
            padding: 6px 12px;
            color: #3071a9;
            font-size: 12px;

            &:hover {
                cursor: copy;
                z-index: 100;
            }
        }
    }
}

.dashboard-static-tile {
    border: 1px solid#e6e6e6;
    height: 130px;
    text-align: left;
    box-shadow: 0 0 5px #e6e6e6;
    padding: 6px;

    .panel-heading {
        font-size: 11px;
        line-height: 14px;
        border-bottom: 1px solid #e6e6e6;
        height: 34px;
        min-height: 34px;
        padding: 1px 0;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        color: gray;
    }

    .content {
        font-size: 28px;
        text-align: center;
        margin: 12px;
        min-height: 40px;
        line-height: 40px;
    }

    .footer {
        font-size: 9pt;
        color: gray;
        text-transform: uppercase;
        text-align: center;
        height: 12px;
        line-height: 12px;
    }

    .rotator {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: none;
            margin: 0;
            padding: 0;
            height: 76px;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            position: relative;

            &:nth-child(1) {
                display: block;
            }

            .page-count {
                background-color: #e6e6e6;
                float: right;
                padding: 2px;
                color: gray;
                border-top-left-radius: 11px;
                padding-left: 6px;
                font-size: 11px;
                position: absolute;
                right: -6px;
                bottom: 0;
            }
        }
    }
}

.dashboard-widget {

    .small-figure {
        text-align: center;
        font-size: 1.5em;
        line-height: 1.2em;
        font-weight: 300;
    }

    .medium-figure {
        text-align: center;
        font-size: 2.5em;
        line-height: 1.1em;
        font-weight: 300;
    }


    .big-figure {
        text-align: center;
        font-size: 4em;
        line-height: 1em;
        font-weight: 300;
    }

    .footer-link {
        margin-top: 0.5em;
        text-align: center;
    }

    .figure-label {
        text-align: center;
        text-transform: uppercase;
        font-size: 0.9em;
        line-height: 1.1em;
        font-weight: 300;
    }

    .small-figure > .currency,
    .medium-figure > .currency,
    .big-figure > .currency {
        text-transform: uppercase;
        font-size: 0.7em;
        padding-right: 0.4em;
        line-height: 1em;
    }

    .figure-label + .small-figure,
    .figure-label + .medium-figure,
    .figure-label + .big-figure {
        margin-top: 10px;
    }

    .panel-body {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.dashboard-controls {
    font-size: 8pt;
    float: right;
    font-family: Verdana;
    margin: 0;
    padding: 0.2em 1em 0.2em 2em;
    color: fade-out(#222222, 0.5);
    margin-top: -39px;

    a {
        color: fade-out(#222222, 0.5) !important;
    }

    &:hover {
        color: fade-out(#222222, 0);

        a {
            color: fade-out($content_link_color, 0) !important;
        }
    }
}



@media (max-width: $screen-sm-max) {

    .gridster {
        margin: 0;
        padding: 0;
        position: initial;
        overflow: visible;

        > ul {
            position: relative;
            margin: 0 !important;
            padding: 0 !important;
            width: auto !important;
            height: auto !important;

            > li.gs-w {
                width: 100%;
                position: relative;
                left: 0;
                top: 0;

                &[class^="col-"], &[class*=" col-"] {
                    height: auto;
                    padding: 0 5px !important;
                }
            }

            li[data-sizex], li[data-sizey] {
                width: inherit;
                height: inherit;
            }
        }
    }
}


@media (max-width: $screen-sm-max) {

    .gridster > ul > li.gs-w {
        width: 50%;
    }
}

@media (max-width: $screen-xs-max) {

    .gridster > ul > li.gs-w {
        width: 100%;
    }
}
