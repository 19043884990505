/* ========================================================================
     Component: offsidebar
 ========================================================================== */
 
//
// A sidebar in offcanvas mode
// Open status toggled via JS
// -----------------------------------------

$offsidebar-bg:           #fff;
$offsidebar-border-color: grayscale(darken($offsidebar-bg, 20%));
$offsidebar-color:        $text-color;
$offsidebar-wd:           $aside-wd + 15px;      // match the width with the main aside component

.offsidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: $offsidebar-wd;
  margin-top: ($navbar-height + 2) * 2;
  border-left: 1px solid $offsidebar-border-color;
  background-color: $offsidebar-bg;
  color: $offsidebar-color;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1;
  
  > nav {
    min-height: 100%;
    overflow: hidden;
    -webkit-transform: translateZ(0px);
  }

  .progress {
    border: 0;
  }

  .nav > li > a {
    &:hover, &:focus {
      background-color: rgba(0,0,0,.025);
    }
  }

  .tab-content {
    padding: 0;
    border: 0;
  }
  .nav-tabs.nav-justified > li {
    > a, > a:hover, > a:focus {
      background-color: transparent;
      border: 0;
      border-right: 1px solid rgba(0,0,0,.05);
      border-bottom: 1px solid rgba(0,0,0,.05);
      border-radius: 0;
      color: $text-muted;
    }
    &.active > a {
      //border-bottom-color: $brand-primary; 
      color: $brand-primary;
    }
  }

  @media only screen and (min-width: $mq-tablet) {
    margin-top: $navbar-height;
  }
}

// Global open status (attach to body)
.offsidebar-open  {
  .offsidebar {
    overflow-y: auto;
    z-index: $z-index-main-section + 5;
  }
  @media only screen and (min-width: $mq-tablet) {
    overflow-y: auto;
  }
}

// No tranformation devices
.offsidebar {
  right: -$offsidebar-wd;
}
.no-csstransforms3d {
  .offsidebar-open {
    .offsidebar {
      right: 0;
    }
  }
}

/* Transformation ready devices*/
.csstransforms3d {
  .offsidebar {
    right: 0;
    @include translate3d( ($offsidebar-wd), 0, 0);
    @include transition-transform(.3s ease, z-index 0s linear .3s);
  }
  .offsidebar-open {
    .offsidebar {
      @include translate3d(0, 0, 0);
      @include transition-transform(.3s ease);
    }
  }
}
