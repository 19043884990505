@import "variables";
@import "mixins";

#crm-system {
    
    .smallhr {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .crm-more-info-content {
        display: none;
    }

    .help-block {
        display: none;
    }

    .crm-action-links {
        list-style: none;
        margin-left: 0;
        margin-bottom: 3em;

        li {
            line-height: 1.3em;
            margin-bottom: 0.5em;

            form {
                display: inline;
            }

            input.crm-form-btn, input.crm-form-btn:hover, input.crm-form-btn:focus, input.crm-form-btn:active, input.crm-form-btn:visited {
                border: none !important;
                background: none !important;
                padding: 0 !important;
                margin: 0 !important;
                color: #06C !important;
                font-size: 1.3em !important;
            }

            input[type=button], input[type=search], input[type=reset], input[type=submit], button {
                @extend input.crm-form-btn;
                font-size: 1em !important;

                &:hover, &:focus, &:active {
                    @extend input.crm-form-btn;
                    font-size: 1em !important;
                }
            }

            i {
                font-size: 1.1em;
            }

            a {
                vertical-align: text-bottom;
            }
        }
    }

    .crm-social-link {
        i {
            color: #888888;

            &:hover {
                color: #333333;
            }

            &.portal-icon-facebook:hover {
                color: #3b5998;
            }

            &.portal-icon-linkedin:hover {
                color: #007bb6;
            }

            &.portal-icon-twitter:hover {
                color: #00aced;
            }
        }
    }
}

