@import "variables";
@import "mixins";


.dragHandle {
	background-image: url(#{$image_base}/icons/dragHandle.png);
	background-color: White;
	background-repeat: repeat;
	width: 6px;
}

.money {
    > .currency {
        float: left;
    }

    > .small-currency {
        font-size: smaller;
        margin-right: 0.2em;
    }

    > .value {
    }
}
