﻿.panel-heading[data-toggle="collapse"] {
    cursor: pointer;

    .collapse-state::before {
        content: "\e81c";
    }

    &.collapsed .collapse-state::before {
        content: "\e81a";
    }
}