@import "variables";
@import "mixins";

@-webkit-keyframes cm-spin {
    0% {
        -webkit-transform: translateY(-50%) rotate(0deg);
        transform: translateY(-50%) rotate(0deg);
    }

    100% {
        -webkit-transform: translateY(-50%) rotate(359deg);
        transform: translateY(-50%) rotate(359deg);
    }
}

@-o-keyframes cm-spin {
    0% {
        -webkit-transform: translateY(-50%) rotate(0deg);
        -o-transform: translateY(-50%) rotate(0deg);
        transform: translateY(-50%) rotate(0deg);
    }

    100% {
        -webkit-transform: translateY(-50%) rotate(359deg);
        -o-transform: translateY(-50%) rotate(359deg);
        transform: translateY(-50%) rotate(359deg);
    }
}

@keyframes cm-spin {
    0% {
        -webkit-transform: translateY(-50%) rotate(0deg);
        -o-transform: translateY(-50%) rotate(0deg);
        transform: translateY(-50%) rotate(0deg);
    }

    100% {
        -webkit-transform: translateY(-50%) rotate(359deg);
        -o-transform: translateY(-50%) rotate(359deg);
        transform: translateY(-50%) rotate(359deg);
    }
}


.context-menu-icon::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 2em;
    font-family: "context-menu-icons";
    font-size: 1em;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    color: #2980b9;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.context-menu-icon.context-menu-hover:before {
    color: #fff;
}

.context-menu-icon.context-menu-disabled::before {
    color: #bbb;
}

.context-menu-icon.context-menu-icon-loading:before {
    -webkit-animation: cm-spin 2s infinite;
    -o-animation: cm-spin 2s infinite;
    animation: cm-spin 2s infinite;
}

.context-menu-icon.context-menu-icon--fa {
    display: list-item;
    font-family: inherit;
}

.context-menu-icon.context-menu-icon--fa::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 2em;
    font-family: FontAwesome;
    font-size: 1em;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    color: #2980b9;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.context-menu-icon.context-menu-icon--fa.context-menu-hover:before {
    color: #fff;
}

.context-menu-icon.context-menu-icon--fa.context-menu-disabled::before {
    color: #bbb;
}

.context-menu-list {
    position: absolute;
    display: inline-block;
    min-width: 13em;
    max-width: 26em;
    padding: .25em 0;
    margin: .3em;
    font-family: inherit;
    font-size: inherit;
    list-style-type: none;
    background: #fff;
    border: 1px solid #bebebe;
    border-radius: .2em;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
    box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
}

.context-menu-item {
    position: relative;
    padding: .2em 2em;
    color: #2f2f2f;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
}

.context-menu-separator {
    padding: 0;
    margin: .35em 0;
    border-bottom: 1px solid #e6e6e6;
}

.context-menu-item > label > input,
.context-menu-item > label > textarea {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.context-menu-item.context-menu-hover {
    color: #fff;
    cursor: pointer;
    background-color: #2980b9;
}

.context-menu-item.context-menu-disabled {
    color: #bbb;
    cursor: default;
    background-color: #fff;
}

.context-menu-input.context-menu-hover {
    color: #2f2f2f;
    cursor: default;
}

.context-menu-submenu:after {
    position: absolute;
    top: 50%;
    right: .5em;
    z-index: 1;
    width: 0;
    height: 0;
    content: '';
    border-color: transparent transparent transparent #2f2f2f;
    border-style: solid;
    border-width: .25em 0 .25em .25em;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}


.context-menu-icon {
    min-height: 18px;
    background-repeat: no-repeat;
    background-position: 4px 2px;
}

.context-menu-icon-new-document { 
    background-image: url(#{$image_base}/contextMenu/blue-document--plus.png); 
}

.context-menu-icon-close-document {
    background-image: url(#{$image_base}/contextMenu/blue-document--minus.png);
}

.context-menu-icon-extend-document {
    background-image: url(#{$image_base}/contextMenu/blue-document-clock.png);
}

.context-menu-icon-new-activity {
    background-image: url(#{$image_base}/contextMenu/clock--plus.png);
}

.context-menu-icon-view-activities {
    background-image: url(#{$image_base}/contextMenu/clock--arrow.png);
}

.context-menu-icon-mail-send {
    background-image: url(#{$image_base}/contextMenu/mail-send.png);
}

.context-menu-icon-minus {
    background-image: url(#{$image_base}/contextMenu/minus.png);
}

.context-menu-icon-minus-small {
    background-image: url(#{$image_base}/contextMenu/minus-small.png);
}


/**
 * Inputs
 */
.context-menu-item.context-menu-input {
    padding: .3em .6em;
}

/* vertically align inside labels */
.context-menu-input > label > * {
    vertical-align: top;
}

/* position checkboxes and radios as icons */
.context-menu-input > label > input[type="checkbox"],
.context-menu-input > label > input[type="radio"] {
    position: relative;
    top: .12em;
    margin-right: .4em;
}

.context-menu-input > label {
    margin: 0;
}

.context-menu-input > label,
.context-menu-input > label > input[type="text"],
.context-menu-input > label > textarea,
.context-menu-input > label > select {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.context-menu-input > label > textarea {
    height: 7em;
}

.context-menu-item > .context-menu-list {
    top: .3em;
    /* re-positioned by js */
    right: -.3em;
    display: none;
}

.context-menu-item.context-menu-visible > .context-menu-list {
    display: block;
}

.context-menu-accesskey {
    text-decoration: underline;
}
