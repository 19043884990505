@import "variables";
@import "mixins";

#ejGridStateContainer {
    margin-bottom: 0;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #ddd;

    .panel-body {

        select {
            min-width: 170px;
            outline: none;

            & + button {
                margin-left: 4.4px;
            }
        }

        .e-ccButton {
            margin-top: 0 !important;
            height: 31.5px !important;
            float: none !important;
        }
    }

    & + .e-grid {
        margin-top: 0 !important;

        & > .e-ccButton {
            margin-right: 15px;
            margin-top: -47px !important;
            height: 31px;
        }
    }
}
