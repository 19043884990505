﻿$font-face: "Source Sans Pro", sans-serif;
$font-size: 13px;

.e-grid {
    font-family: $font-face;
}

.e-input, .e-tooltipbox, .e-btn, .e-time-popup, .e-datepicker.e-calendar,
.e-menu, .e-uploadbox .e-file-name-txt, .e-uploadbox .e-selectpart,
.e-uploadbox .e-uploadbtn, .e-waitpopup-pane .e-text, .e-tagcloud,
.e-toolbar, .e-progressbar, .e-scale .e-tick .tick-value, .e-datetime-popup .e-dt-button,
.e-grid, .e-ejinputtext, .e-pager, .e-fileexplorer, .e-textbox, .e-textarea {
    font-family: $font-face;
}

.e-waitpopup-pane .e-image {
    background-color: #fff;
}

.e-grid .e-headercelldiv {
    -ms-text-overflow: ellipsis !important;
    -o-text-overflow: ellipsis !important;
    text-overflow: ellipsis !important;
    padding-top: 13px !important;
}

.e-columnChooser .e-scroller {
    height: 256px !important;
}

.e-columnChooser .e-content {
    padding-top: 28px;
}

.e-grid .e-rowcell {
    font-size: 13px !important;
}

.e-grid .e-ccButton {
    margin-top: -40px !important;
}

e-button e-js e-btn-normal e-btn e-select e-widget e-txt e-corner {
    margin-right: 4px !important;
}

.e-columnChooser .e-searchbox {
    position: absolute !important;
    width: 85%;
    z-index: 9;
}

.e-grid .e-gridtoolbar li {
    padding: 0 0 4px 3px !important;
}

#GridliScrollerDiv .e-content {
    height: 100% !important;
}

.e-toolbar > .e-horizontal .e-hover, .e-toolbar > .e-vertical > .e-hover {
    background: #ebebeb !important;
    border-color: #ccc !important;
}

.e-grid .e-toolbaricons {
    line-height: 0 !important;
    padding-top: 12px !important;
}

div.e-dialog.e-columnChooser {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 228px !important;
    overflow: initial;
}

.e-columnChooserSplitter {
    margin-bottom: -3px !important;
}

.e-columnChooserListDiv {
    text-align: left;
}

.e-columnChooserListDiv label {
    text-align: left;
}

.e-grid .e-icon {
    color: #333 !important;
}

.e-grid .e-groupdroparea {
    line-height: 30px;
    font-size: 12px !important;
    color: #333;

    .e-groupheadercell {
        line-height: initial;
        font-size: $font-size !important;
        padding: 4px 8px !important;
        text-indent: 0 !important;
    }
}

.e-btn, .e-tbtn, .e-split-btn {
    font-family: $font-face !important;
    font-size: 12px !important;
}

.e-pager .e-numericitem {
    font-family: $font-face !important;
    font-size: 12px !important;
}

.e-columnChooser {
    font-family: $font-face !important;
}

.e-excelfilter.e-dlgcontainer {
    font-family: $font-face !important;
}

.e-dialog.e-dialog-wrap {
    font-family: $font-face !important;
}

.e-ddl .e-input, .e-input, .e-ddl-popup, .e-ddl .e-options {
    font-family: $font-face !important;
}

.e-atc .e-in-wrap, .e-atc .e-input, .e-atc-popup, .e-atc .e-options {
    font-family: $font-face !important;
    font-size: $font-size !important;
    text-align: left;
}

.e-grid {

    td {
        font-family: $font-face !important;
        font-size: $font-size !important;
    }

    .e-headercell, .e-headercelldiv, .e-rowcell {
        font-family: $font-face !important;
        font-size: $font-size !important;
    }

    .e-columnChooserListDiv {
        > label {
            font-family: $font-face !important;
            font-size: $font-size !important;
        }
    }

    .e-excelfilter.e-dlgcontainer {
        font-family: $font-face !important;
        font-size: $font-size !important;
    }
}

.e-dialog {
    font-family: $font-face !important;
    font-size: 12px !important;
}

.e-numericcontainer {
    .e-numericitem {
        &.e-active, &.e-active > a {
            background: #f4f4f4 !important;
            background: -moz-linear-gradient(top,#f4f4f4,#e9e9e9) !important;
            background: -webkit-gradient(linear,left top,left bottom,color-stop(0,#f4f4f4),color-stop(100%,#e9e9e9)) !important;
            background: -o-linear-gradient(top,#f4f4f4,#e9e9e9) !important;
            background-image: linear-gradient(rgb(244, 244, 244), rgb(233, 233, 233)) !important;
            background-position-x: initial !important;
            background-position-y: initial !important;
            background-size: initial !important;
            background-attachment: initial !important;
            -moz-background-origin: initial !important;
            -webkit-background-origin: initial !important;
            background-origin: initial !important;
            -moz-background-clip: initial !important;
            -webkit-background-clip: initial !important;
            background-clip: initial !important;
            background-color: initial !important;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f4f4',endColorstr='#e9e9e9',GradientType=0) !important;
        }
    }
}

.e-menu {
    &.e-vertical .e-list {
        line-height: 30px !important;

        &.e-active, &.e-active > a {
            background: #f4f4f4 !important;
            background: -moz-linear-gradient(top,#f4f4f4,#e9e9e9) !important;
            background: -webkit-gradient(linear,left top,left bottom,color-stop(0,#f4f4f4),color-stop(100%,#e9e9e9)) !important;
            background: -o-linear-gradient(top,#f4f4f4,#e9e9e9) !important;
            background-image: linear-gradient(rgb(244, 244, 244), rgb(233, 233, 233)) !important;
            background-position-x: initial !important;
            background-position-y: initial !important;
            background-size: initial !important;
            background-attachment: initial !important;
            -moz-background-origin: initial !important;
            -webkit-background-origin: initial !important;
            background-origin: initial !important;
            -moz-background-clip: initial !important;
            -webkit-background-clip: initial !important;
            background-clip: initial !important;
            background-color: initial !important;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f4f4',endColorstr='#e9e9e9',GradientType=0) !important;
            border-color: #f4f4f4 !important;
        }
    }

    .e-list {
        font-family: $font-face !important;
        font-size: $font-size !important;

        > a, > span {
            font-family: $font-face !important;
            font-size: $font-size !important;
        }

        ul .e-list {
            line-height: 24px !important;
        }
    }
}

.e-menu .e-ejinputtext {
    font-family: $font-face !important;
    font-size: $font-size !important;
}

.e-sortasc, .e-sortdesc {
    font-size: 15px;
}

.e-input, .e-tooltip, .e-btn, .e-time-popup, .e-datepicker.e-calendar, .e-menu, .e-uploadbox .e-file-name-txt, .e-uploadbox .e-selectpart, .e-uploadbox .e-uploadbtn, .e-waitpopup-pane .e-text, .e-tagcloud, .e-toolbar, .e-progressbar, .e-scale .e-tick .tick-value, .e-datetime-popup .e-dt-button, .e-grid, .e-ejinputtext, .e-pager {
    font-family: $font-face !important;
    font-size: $font-size !important;
}

.e-btn.e-txt {
    padding: 0 !important;
}

.e-excelfilter .e-btncontainer.e-fields {
    width: auto !important;
    margin: 0 18px 0 0 !important;
    text-align: right !important;
}

.e-optable {
    td {
        vertical-align: middle !important;

        .e-radiobtn-wrap {
            vertical-align: top !important;
        }
    }
}

.e-ddl-popup {
    font-family: $font-face !important;
    font-size: $font-size !important;
    text-align: left !important;
}

/*Bug fixes*/

/*ejGrid: When filtering a date, the calendar header is messed up and the next-month date isn't visible*/
.e-datepicker .e-header .e-text {
    width: auto;
}
