// Override variables in the default theme
//$brand-primary: #23b7e5 !global;
//$brand-info: #23b7e5 !global;

/*@import "scss/variables";*/
$rootPath: "" !default;
$image_base: "#{$rootPath}/assets/theme/v000/images" !default;

@import "scss/bootstrap-variables";
@import "scss/mixins";
@import "scss/mixins/labels";

// Import the main theme file
@import "../../Angle/app";
@import "scss/angle-override";
@import "scss/BareLayout-Override";

@import "scss/jquery-ui-1.11.4";
@import "scss/jquery-ui-1.11.4-smoothness";
@import "scss/jquery.contextMenu";
@import "scss/jquery.ui.mods";
@import "scss/jquery-tagit";
@import "scss/_dateTimePicker";
@import "scss/ejGridState";
@import "scss/dumpingGround";
@import "scss/tasks";

@import "scss/bootstrap-collapse";

@import "../../../../bower_components/gridster-js/dist/jquery.gridster.scss";
@import "../../../../bower_components/fontawesome/scss/font-awesome";
@import "../../../../bower_components/simple-line-icons/scss/simple-line-icons";
@import "../../../../node_modules/codemirror/lib/codemirror.scss";
@import "../../../../node_modules/easymde/src/css/easymde.scss";
@import "../../../../bower_components/intl-tel-input/src/css/intlTelInput.scss";
@import "../../../../bower_components/izitoast/dist/css/iziToast.scss";
@import "scss/_iziToast-override.scss";

@import "scss/icons";
@import "scss/dashboards";
@import "scss/globalSearch";
@import "scss/crmSystem";
@import "scss/hrSystem";
@import "scss/intl-tel-input-override";

@import "scss/portalFont";
@import "scss/portalFont-animation";
@import "scss/portalFont-customizations";

@import "scss/syncfusion-override";

@import "scss/print";
@import "scss/bootstrap-grid-print-small";

.navbar-brand { height: 55px; }
.margin-bottom-xs {
    margin-bottom: 5px;
}
.margin-bottom-sm {
    margin-bottom: 10px;
}
.margin-bottom-md {
    margin-bottom: 15px;
}
.margin-bottom-lg {
    margin-bottom: 20px;
}
.panel > .list-group .list-group-item {
    margin-bottom: 0;
    border-width: 1px 0;
}

.nav > li > a:focus, .nav > li > a:hover {
    background-color: transparent;
}

.panel .panel-heading {
    background-color: transparent;
}

#documentHeader img {
    width: 161px;
    height: 50px;
}

.ui-dialog {
    z-index: 200 !important;
}

.tab-pane-padded {
    padding: 15px !important;
}

//override for jquery-ui model

ul.ui-autocomplete {
    z-index: 2001;
}

@media print {
    .tab-content > .tab-pane {
        display: block !important;
        opacity: 1 !important;
        visibility: visible !important;
    }

    .nav-tabs {
        display: block !important;
    }
}

.broken-glass {
    background-image: url(#{$rootPath}/assets/images/broken-glass.png);
    background-position: right top;
    background-repeat: no-repeat;
}
