@import "variables";
@import "mixins";

.icon-favourite-on, .icon-favourite-off:hover {
    margin-right: 0.5em;
    font-size: 0.8em;
    cursor: pointer;
    color: yellow;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: orange;
}

.icon-favourite-off, .icon-favourite-on:hover {
    margin-right: 0.5em;
    font-size: 0.8em;
    cursor: pointer;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #888;
}