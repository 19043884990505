﻿.iti-flag {
    background-image: url("#{$rootPath}/assets/images/icons/flags.png");
}

.intl-tel-input .country-list {
    height: 200px;
}

.intl-tel-input {
    width: 100%;
}