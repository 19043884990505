﻿#app-tasks {

    #finishedTasksList {
        cursor: pointer;
    }

    a {
        text-decoration: none !important;
    }

    .done-true {
        text-decoration: line-through;
        color: grey;
    }

    label.done-false, label.done-true {
        padding-left: 0px;
    }

    span.fa.fa-check {
        margin-right: 0px;
    }

    label.listlabels.done-false, label.listlabels.done-true {
        margin-left: 5px;
    }

    div.checkbox.c-checkbox {
        margin-bottom: 0px;
    }

    #finishedTasksList {
        cursor: pointer;
    }

    [data-toggle="collapse"] {
        cursor: pointer;

        > .portal-icon-plus-squared {
            display: none;
        }

        > .portal-icon-minux-squared {
            display: inline-block;
        }
    }

    [data-toggle="collapse"].collapsed {

        > .portal-icon-plus-squared {
            display: inline-block;
        }

        > .portal-icon-minus-squared {
            display: none;
        }
    }
}
