@import "variables";
@import "mixins";

.portal-icon-spin6 {
    font-size: 1.4em;
    line-height: inherit;
}

.btn, button, input[type=button], input[type=reset], input[type=submit] {

    [class^="portal-icon-"]:before, [class*=" portal-icon-"]:before {
        font-size: 14px;
        margin: 0 6px 0 0;
        position: relative;
        top: 1px;
    }

    .icon-only:before {
        margin: 0;
    }
}



#UserLoggedOptions {

    a {
        vertical-align: middle;

        [class^="portal-icon-"]:before, [class*=" portal-icon-"]:before {
            color: #757575;
            vertical-align: text-bottom;
            font-size: 1.2em;
            margin: 0 0.3em 0 0.6em;
            padding: 0;
        }
    }
}

.context-menu-list.font-icons {
	.context-menu-item {
	
	padding-left: 29px;
	&.icon {
		padding-left: 6px;
	}
	}
}

.context-menu-item.icon {
	&:before {
		color: #757575;
		font-size: 14px;
		margin-right: 6px;
		vertical-align: middle;
	}
}
