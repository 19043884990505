﻿@import "variables";
@import "mixins";

@font-face {
  font-family: 'portal';
  src: url($rootPath + '/assets/fonts/portal/portal.eot?88075693');
  src: url($rootPath + '/assets/fonts/portal/portal.eot?88075693#iefix') format('embedded-opentype'),
       url($rootPath + '/assets/fonts/portal/portal.woff2?88075693') format('woff2'),
       url($rootPath + '/assets/fonts/portal/portal.woff?88075693') format('woff'),
       url($rootPath + '/assets/fonts/portal/portal.ttf?88075693') format('truetype'),
       url($rootPath + '/assets/fonts/portal/portal.svg?88075693#portal') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'portal';
    src: url('/assets/fonts/portal/portal.svg?46185440#portal') format('svg');
  }
}
*/
 
 [class^="portal-icon-"]:before, [class*=" portal-icon-"]:before {
  font-family: "portal";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.portal-icon-progress-2:before { content: '\e800'; } /* '' */
.portal-icon-emo-happy:before { content: '\e801'; } /* '' */
.portal-icon-dollar:before { content: '\e802'; } /* '' */
.portal-icon-down-dir:before { content: '\e803'; } /* '' */
.portal-icon-down-open:before { content: '\e804'; } /* '' */
.portal-icon-angle-down:before { content: '\e805'; } /* '' */
.portal-icon-power:before { content: '\e806'; } /* '' */
.portal-icon-logout:before { content: '\e807'; } /* '' */
.portal-icon-trash:before { content: '\e808'; } /* '' */
.portal-icon-cog:before { content: '\e809'; } /* '' */
.portal-icon-cog-1:before { content: '\e80a'; } /* '' */
.portal-icon-info-circled:before { content: '\e80b'; } /* '' */
.portal-icon-arrows-cw:before { content: '\e80c'; } /* '' */
.portal-icon-search:before { content: '\e80d'; } /* '' */
.portal-icon-vcard:before { content: '\e80e'; } /* '' */
.portal-icon-user:before { content: '\e80f'; } /* '' */
.portal-icon-users:before { content: '\e810'; } /* '' */
.portal-icon-user-add:before { content: '\e811'; } /* '' */
.portal-icon-user-1:before { content: '\e812'; } /* '' */
.portal-icon-users-1:before { content: '\e813'; } /* '' */
.portal-icon-ok:before { content: '\e814'; } /* '' */
.portal-icon-ok-circled:before { content: '\e815'; } /* '' */
.portal-icon-cancel:before { content: '\e816'; } /* '' */
.portal-icon-cancel-circled:before { content: '\e817'; } /* '' */
.portal-icon-plus:before { content: '\e818'; } /* '' */
.portal-icon-plus-circled:before { content: '\e819'; } /* '' */
.portal-icon-plus-squared:before { content: '\e81a'; } /* '' */
.portal-icon-plus-squared-alt:before { content: '\e81b'; } /* '' */
.portal-icon-minus-squared:before { content: '\e81c'; } /* '' */
.portal-icon-minus-squared-alt:before { content: '\e81d'; } /* '' */
.portal-icon-help:before { content: '\e81e'; } /* '' */
.portal-icon-help-circled:before { content: '\e81f'; } /* '' */
.portal-icon-home:before { content: '\e820'; } /* '' */
.portal-icon-link-ext:before { content: '\e821'; } /* '' */
.portal-icon-attach:before { content: '\e822'; } /* '' */
.portal-icon-tag:before { content: '\e823'; } /* '' */
.portal-icon-tags:before { content: '\e824'; } /* '' */
.portal-icon-download-cloud:before { content: '\e825'; } /* '' */
.portal-icon-upload-cloud:before { content: '\e826'; } /* '' */
.portal-icon-edit:before { content: '\e827'; } /* '' */
.portal-icon-doc:before { content: '\e828'; } /* '' */
.portal-icon-file-pdf:before { content: '\e829'; } /* '' */
.portal-icon-file-word:before { content: '\e82a'; } /* '' */
.portal-icon-file-excel:before { content: '\e82b'; } /* '' */
.portal-icon-phone:before { content: '\e82c'; } /* '' */
.portal-icon-phone-squared:before { content: '\e82d'; } /* '' */
.portal-icon-cog-alt:before { content: '\e82e'; } /* '' */
.portal-icon-wrench:before { content: '\e82f'; } /* '' */
.portal-icon-doc-text:before { content: '\e830'; } /* '' */
.portal-icon-rss:before { content: '\e831'; } /* '' */
.portal-icon-mail:before { content: '\e832'; } /* '' */
.portal-icon-mail-alt:before { content: '\e833'; } /* '' */
.portal-icon-attention:before { content: '\e834'; } /* '' */
.portal-icon-info:before { content: '\e835'; } /* '' */
.portal-icon-forward:before { content: '\e836'; } /* '' */
.portal-icon-level-up:before { content: '\e837'; } /* '' */
.portal-icon-up-dir:before { content: '\e838'; } /* '' */
.portal-icon-left-dir:before { content: '\e839'; } /* '' */
.portal-icon-right-dir:before { content: '\e83a'; } /* '' */
.portal-icon-undo:before { content: '\e83b'; } /* '' */
.portal-icon-calendar:before { content: '\e83c'; } /* '' */
.portal-icon-warehouse:before { content: '\e83d'; } /* '' */
.portal-icon-check:before { content: '\e83e'; } /* '' */
.portal-icon-check-empty:before { content: '\e83f'; } /* '' */
.portal-icon-hdd:before { content: '\e840'; } /* '' */
.portal-icon-floppy:before { content: '\e841'; } /* '' */
.portal-icon-level-up-1:before { content: '\e842'; } /* '' */
.portal-icon-level-down:before { content: '\e843'; } /* '' */
.portal-icon-level-down-1:before { content: '\e844'; } /* '' */
.portal-icon-basket:before { content: '\e845'; } /* '' */
.portal-icon-key:before { content: '\e846'; } /* '' */
.portal-icon-left-circled:before { content: '\e847'; } /* '' */
.portal-icon-right-circled:before { content: '\e848'; } /* '' */
.portal-icon-up-circled:before { content: '\e849'; } /* '' */
.portal-icon-down-circled:before { content: '\e84a'; } /* '' */
.portal-icon-to-end:before { content: '\e84b'; } /* '' */
.portal-icon-to-start:before { content: '\e84c'; } /* '' */
.portal-icon-fast-forward:before { content: '\e84d'; } /* '' */
.portal-icon-fast-backward:before { content: '\e84e'; } /* '' */
.portal-icon-industrial-building:before { content: '\e84f'; } /* '' */
.portal-icon-unlink:before { content: '\e850'; } /* '' */
.portal-icon-file-archive:before { content: '\e851'; } /* '' */
.portal-icon-inbox:before { content: '\e852'; } /* '' */
.portal-icon-box:before { content: '\e853'; } /* '' */
.portal-icon-ticket:before { content: '\e854'; } /* '' */
.portal-icon-back:before { content: '\e855'; } /* '' */
.portal-icon-barcode:before { content: '\e856'; } /* '' */
.portal-icon-spin6:before { content: '\e857'; } /* '' */
.portal-icon-facebook:before { content: '\e858'; } /* '' */
.portal-icon-googleplus:before { content: '\e859'; } /* '' */
.portal-icon-linkedin:before { content: '\e85a'; } /* '' */
.portal-icon-twitter:before { content: '\e85b'; } /* '' */
.portal-icon-skype:before { content: '\e85c'; } /* '' */
.portal-icon-mail-1:before { content: '\e85d'; } /* '' */
.portal-icon-location:before { content: '\e85e'; } /* '' */
.portal-icon-na:before { content: '\e85f'; } /* '' */
.portal-icon-graduation-cap:before { content: '\e860'; } /* '' */
.portal-icon-clock:before { content: '\e861'; } /* '' */
.portal-icon-flight:before { content: '\e862'; } /* '' */
.portal-icon-gift:before { content: '\e863'; } /* '' */
.portal-icon-cab:before { content: '\e864'; } /* '' */
.portal-icon-share:before { content: '\e865'; } /* '' */
