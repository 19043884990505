/* ========================================================================
     Component: datatable
 ========================================================================== */

// Search filter
.dataTables_filter {
  @media only screen and (max-width: $mq-desktop) {
    clear: both;
  }
  input {
    margin-left: 10px
  }
}


// Select length filter
.dataTables_length {
  float: left;
  select {
    margin-right: 10px
  }
}

table.dataTable {
  tfoot > tr > th {
    color: #a1a2a3;
    background: #f1f2f3;
  }
}

// datatable inside panels
.panel {
  .dataTables_wrapper {
    overflow: auto;
  }
  .dataTables_wrapper .row {
    margin: 10px 0;
  }
  .dataTable {
    width: 100% !important;
  }
}

.html5buttons {
    float: none;
    text-align: center;
    margin-bottom: 10px;
    @media only screen and (min-width: $mq-desktop) {
        float: right;
        margin-left: 10px;
    }
}

//
// Custom ColVis extensions styles
//

// The main button
div.ColVis {
  @media only screen and (max-width: $mq-desktop) {
    float: none;
    text-align: center;
    button.ColVis_Button, ul.ColVis_collection li {
        float: none;
    }
  }
  .ColVis_Button {
    @extend .btn;
    @extend .btn-default;
    background-color: #fff;
    background-image: none !important;
    // border: 1px solid #e1e2e3;
    box-shadow: 0 0 0 #000 !important;
    cursor: pointer;
    height: 31px;
    > span {
      color: #656565;
    }
  }

}

// Backdrop
.ColVis_collectionBackground {
  display: none;
}

// Labels dropdown
ul.ColVis_collection {
  background-color: #fff !important;
  > li {
    border: 0 !important;
    background-image: none !important;
    box-shadow: 0 0 0 #000 !important;
    margin: 0;
    > label {
        margin: 0;
        > span {
          color: #888;
          vertical-align: top;
          font-weight: normal;
          &:hover {
            color: #555;
          }
        }
        input[type=checkbox]:checked + span,
        input[type=radio]:checked + span {
          background-color: transparent;
        }
    }
  }
}

.ColVis_catcher {
  cursor: pointer;
}

// fix scrollbar
.table-responsive {
  border: 0;
  .dataTables_wrapper {
    .row {
      margin: 0;
    }
  }
}

// place controls correctly
@media only screen and (max-width: $mq-desktop) {
  .dataTables_length {
    float: none;
  }
  div.dataTables_wrapper div.dataTables_length label,
  div.dataTables_filter label,
  div.dataTables_length label,
  div.dataTables_paginate {
    float: none;
    text-align: center !important;
    display: block;
    select {
      display: inline-block;
    }
  }
  .dataTables_wrapper {
    > .row > div {
      float: none;
      text-align: center;
      width: 100%;
    }
  }
}

// Button info popup
div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid $gray;
  box-shadow: 3px 3px 8px rgba( 0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 2100;

  h2 {
    padding: 0.5em;
    margin: 0;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    background-color: #f3f3f3;
    &:before {
      content: "\f00c";
      font-family: FontAwesome;
      color: $brand-success;
      margin-right: 8px;
    }
  }

  > div {
    padding: 1em;
  }
}
