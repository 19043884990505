@import "variables";
@import "mixins";

.ui-autocomplete { max-height: 200px; overflow-y: auto; overflow-x: hidden; z-index: 5000; }
.ui-autocomplete li { color: #333333; font-size: 8pt; text-align: left; }
.ui-autocomplete li a { white-space: nowrap; }
.ui-autocomplete li a span { color: #888888; }
.ui-autocomplete-category { font-weight: bold; font-variant: small-caps; }
#ui-datepicker-div { display: none; }
.ui-icon-button { cursor: pointer; }
.ui-icon-inline { display: inline-block; vertical-align: top; }

/*.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button { font-size: 106%; }*/

.ui-state-disabled, .ui-widget-content .ui-state-disabled { opacity: .45 !important; filter: Alpha(Opacity=45) !important; background-image: none; }

/* Tabs */
.ui-tabs > div > h2 { display: none; }
.ui-tabs.ui-widget { font-size: 100%; }
.ui-tabs ul.ui-tabs-nav { margin: 0 !important; background: #f5f5f5 !important; border-color: #d7d7d7; }
.ui-tabs ul.ui-tabs-nav li { margin: 0 0.2em -1px 0 !important; }
.ui-tabs ul.ui-tabs-nav li a { color: #212121 !important; font-size: 100% !important; }


.ui-datepicker-week-end a, .ui-datepicker-week-end span { opacity: 0.7; }

.ui-dialog-title {
	text-align: left;
}

h3.ui-accordion .ui-accordion-header, .ui-accordion .ui-accordion-header {
    font-size: initial;
}